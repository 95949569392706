import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import "react-phone-input-2/lib/style.css"
import "../../styles/component/popup-signup.scss"
import FormBuilder from "./FormBuilder/Form-Builder"

function PopUpBlog(props) {
  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a valid name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
  ]

  // const errorHandler = (data, response) => {
  //   if (response.errors[0].errorType === "INVALID_EMAIL") {
  //     setSubmissionError({
  //       type: "email",
  //       errorType: response.errors[0].errorType,
  //     })
  //   }
  // }

  // const handleSubmit = items => {
  //   const formData = [...items].slice(0, 4)
  //   var fullname = formData[0].value.trim()
  //   if (fullname.includes(" ")) {
  //     //split Name if includes a empty space
  //     var fname = fullname.substr(0, fullname.indexOf(" "))
  //     var lname = fullname.substr(fullname.indexOf(" ") + 1)
  //     formData[0].value = fname
  //   } else {
  //     //else set Name as first_name
  //     fname = fullname
  //     lname = ""
  //   }
  //   formData.push({ name: "firstname", value: fname })
  //   formData.push({ name: "lastname", value: lname })

  //   var path = currentLocation.pathname
  //   handleFormSubmit(
  //     formData,
  //     process.env.HUBSPOT_EMAIL_SUBSCRIBE_ENDPOINT,
  //     process.env.HUBSPOT_EMAIL_SUBSCRIBE_URL,
  //     "Email subscribe- Superops",
  //     path,
  //     errorHandler
  //   )
  // }

  return (
    <div
      className="popup-signup"
      style={{ display: !props.visibility && "none" }}
    >
      <div
        className="wrap position-fixed"
        onClick={props.togglePopup}
        onKeyDown={props.togglePopup}
        role="button"
        tabIndex={0}
      >
        {/*  */}
      </div>

      <Container className="position-fixed">
        <div className="overflow">
          <div
            className="close position-absolute"
            onClick={props.togglePopup}
            onKeyDown={props.togglePopup}
            role="button"
            tabIndex={0}
          >
            <img
              src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/zpyoXoSXRuWnSQ1KauLF"
              alt="close"
              height="24"
              width="24"
            />
          </div>

          <div className="forms">
            <span className="p16 info">
              <center>
                <p>
                  {" "}
                  Get awesome MSP tips, tricks, and insights right in your
                  inbox.{" "}
                </p>
              </center>
            </span>
            {/* Need to test this  form builder v2 */}
            <FormBuilder
              formClassName="forms"
              errorClassName="error-message"
              inputErrorClassName="error-active"
              data={formdata}
              buttonText="SUBMIT"
              buttonClassName="primary"
              IpStackData
              endpoint={process.env.HUBSPOT_EMAIL_SUBSCRIBE_ENDPOINT}
              url={process.env.HUBSPOT_EMAIL_SUBSCRIBE_URL}
              formName="Email subscribe- Superops"
              path="blog"
            />

            <span className="action p12 a12">
              <center>
                <p>
                  By clicking "Submit", you agree to SuperOps's{" "}
                  <Link to="/terms">Terms of use</Link> and{" "}
                  <Link to="/privacy">Privacy policy</Link>.
                </p>
              </center>
            </span>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default PopUpBlog
