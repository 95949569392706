import { useLocation } from "@reach/router"
import { graphql, Link, StaticQuery } from "gatsby"
import { parse } from "query-string"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import LandingFeaturedAuthors from "../../components/blog/landingFeaturedAuthors"
import LandingFeaturedStories from "../../components/blog/landingFeaturedStories"
import LandingLatestStory from "../../components/blog/landingLatestStory"
import LandingOlderStories from "../../components/blog/landingOlderStories"
import LandingRecentStories from "../../components/blog/landingRecentStories"
import CTA from "../../components/common/CTA"
import Frame from "../../components/common/frame"
import PopUpBlog from "../../components/common/PopUpBlog"
import NavigationBlog from "../../components/navigationBlog"
import Slide from "../../components/utility/slide_content"
function Blog() {
  const location = useLocation()
  const searchParams = parse(location.search)
  const [SHOWPOPUP, setSHOWPOPUP] = useState(false)
  const togglePopup = () => {
    setSHOWPOPUP(!setSHOWPOPUP)
  }
  useEffect(() => {
    searchParams.src === "subscribe" && setSHOWPOPUP(true)
  }, [searchParams.src])

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentBlog {
            SuperOps {
              navigationContents(where: { section: "Library" }) {
                section
                isParentOnly
                parentTitle
                parentDescription
                parentTagBg
                childTitle
                childSlug
                childDescription
                childIcon {
                  url
                }
                parentTagSecondaryBg
                parentSlug
              }

              pages(where: { title: "Blog" }) {
                title
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                ctaBoxes {
                  type
                  theme
                  heading {
                    html
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div className="blog">
            {data.SuperOps.pages.map(page => {
              const { seo, navigationBlock, ctaBoxes } = page
              return (
                <>
                  <Frame seo={seo} ogType="blog" footerClass={"p-0"}>
                    <NavigationBlog
                      links={navigationBlock}
                      background="#fff2f1"
                      showLogo={true}
                      libraryLinks={data.SuperOps.navigationContents}
                    />
                    <div>
                      <PopUpBlog
                        visibility={SHOWPOPUP}
                        togglePopup={togglePopup}
                      />
                    </div>
                    <div className="main-landing">
                      <section className="hero">
                        <Container>
                          <Row>
                            <Col lg={7}>
                              <span className="p16 section-title">
                                <p>LATEST STORY</p>
                              </span>
                              <LandingLatestStory />
                            </Col>
                            <Col lg={5} className="featured-stories">
                              <span className="p16 section-title">
                                <p className="feat-stories-title">
                                  FEATURED STORIES
                                </p>
                                <LandingFeaturedStories />
                              </span>
                            </Col>
                          </Row>
                        </Container>
                      </section>

                      <section className="recent">
                        <Container>
                          <Row>
                            <Col lg={8}>
                              <span className="p16 section-title">
                                <p>RECENT STORIES</p>
                              </span>
                              <LandingRecentStories />
                            </Col>
                            <Col
                              lg={4}
                              className="position-relative featured-authors"
                            >
                              <span className="p16 section-title">
                                <p className="feat-authors-title">
                                  FEATURED AUTHORS
                                </p>
                              </span>
                              <div className="position-absolute author-anchor">
                                <span className="a14">
                                  <Link to="/blog/authors">
                                    {" "}
                                    meet them all{" "}
                                    <img
                                      src="https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/2j9zFnn3SWGuSdxuUPVA"
                                      alt="arrow"
                                      height="5"
                                      width="5"
                                    />{" "}
                                  </Link>
                                </span>
                              </div>
                              <LandingFeaturedAuthors />
                            </Col>
                          </Row>
                        </Container>
                      </section>

                      <section className="cta">
                        <Slide style={{ "--sal-delay": "0.2s" }}>
                          <CTA data={[ctaBoxes[0]]} maxBoxWidth="1104px" />
                        </Slide>
                      </section>

                      <section className="older">
                        <Container>
                          <span className="p16 section-title">
                            <p>OLDER STORIES</p>
                          </span>
                          <LandingOlderStories />
                        </Container>
                      </section>
                    </div>
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default Blog
